<template>
  <v-container>
    <v-row v-for="post in posts.data" :key="post.id">
      <v-col cols="2">
        <img :src="baseURL + post.image" width="100%" style="border-radius: 15px">
      </v-col>
      <v-col cols="10">
        <v-card style="border-radius: 15px; background-color: #f2f3f7; height: 100%"
                :to="'/bar-news/' + post.id">
          <v-card-title style="font-size: 18px; font-family: Blinker-Bold; color: #48b284">
            {{ post.title }}
          </v-card-title>
          <v-card-text style="font-size: 16px;!important; font-family: Blinker-Regular;!important; color: #444444!important;"
                       v-html="post.text.replace(/(<([^>]+)>)/gi, '').substr(0, 250)">
          </v-card-text>
          <v-card-actions style="font-size: 16px; font-family: Blinker-Regular; color: #a8a8a8;
           position: absolute; right: 10px; bottom: 10px!important;">
            {{ post.created_at.substr(0, 10) }}
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" xs="12">
        <v-pagination
            v-model="posts.current_page"
            :length="posts.last_page"
            circle
            @input="getPosts(posts.current_page)"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "BarNews",
  data() {
    return {
      posts: {}
    }
  },
  mounted() {
    this.getPosts()
  },
  methods: {
    getPosts(page = 1) {
      window.axios
          .get('/api/bar-news?page=' + page)
          .then((res) => {
            this.posts = res.data.data
          })
          .catch((err) => {
            console.log(err)
          })
    },
  }
}
</script>

<style scoped>

.page-title {
  font-family: Blinker-Bold, serif;
  font-size: 40px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 1;
  line-height: normal;
  letter-spacing: normal;
  color: #ffbe66;
}
.custom-line {
  font-size: 20px !important;
  margin-top: 10px !important;
  border-color: #ffbe66;
  border-left: 1px !important;
  margin-right: 20px !important;
  height: 48px !important;
  background: #ffbe66;
  transform: rotateZ(40deg) !important;
  max-width: 50px !important;
}

</style>